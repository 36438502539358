<template>
  <div class="privacy">
    <div class="main">
      <img
        id="black-ground"
        class="black-ground"
        src="https://thcdn.gggamedownload.com/source/assets/yinsi/dh.png"
      >
      <img
        class="dh-ground"
        src="https://thcdn.gggamedownload.com/source/assets/yinsi/logo.png"
      >
      <div class="help-content">
        <div class="help-box">
          <ul>
            <li>
              <div class="bottom problem">
                <div class="p_content">
                  <p class="p_title title">域起公司隐私政策</p>

                  <p>本版更新时间：2021年8月6日</p>
                  <p>
                    欢迎您使用和接受深圳市壹方互娱网络科技有限公司及各关联公司（以下统称“域起公司”或“我们”）提供的产品和服务（以下统称“域起公司服务”）。您在使用域起公司服务时，域起公司可能会收集和使用您的相关信息。域起公司承诺保护使用域起公司的产品和服务之用户（以下称“用户”或“您”）的个人信息和隐私安全。本《隐私政策》适用于域起公司提供的一切域起公司服务。当您使用域起公司任何单项服务时，您同意接受本《隐私政策》以及域起公司在该单项服务中发出的特定隐私信息类政策条款（下列称“特定条款”）的保护，在此情况下特定条款与本政策条款同时对您产生效力。例如：使用域起公司在线游戏服务的用户，同时适用本《隐私政策》以及《网络游戏用户隐私权保护和个人信息利用政策》（见附件）。如特定条款与本《隐私政策》条款存在同类条款约定不一致的情形，则在特定条款约束范围内应以特定条款为准。如域起公司提供的某一单项服务不适用本《隐私政策》的，该服务中会以适当方式明示排除适用本《隐私政策》。
                    域起公司将不时地会检查隐私政策，因此有关的措施会随之变化。域起公司恳请您定期光顾本页面，以确保对域起公司《隐私政策》最新版本始终保持了解。在阅读完本政策之后，如您对本《隐私政策》或与本《隐私政策》相关的事宜有任何问题，请致电400-903-1158联系域起公司。
                    您使用或继续使用域起公司服务，都表示您同意域起公司按照本《隐私政策》收集、使用、储存和分享您的信息。
                  </p>
                  <p>目录</p>
                  <h3>一、收集和使用个人信息的范围、方式和目的收集和使用个人信息的范围、方式和目的</h3>
                  <h3>二、如何收集和使用信息</h3>
                  <h3>三、 如何使用COOKIES技术</h3>
                  <h3>四、可能分享、转让和披露的信息</h3>
                  <h3>五、如何保留、储存和保护信息</h3>
                  <h3>六、如何管理您的信息</h3>
                  <h3>七、第三方服务 </h3>
                  <h3>八、年龄限制</h3>
                  <h3>九、域起儿童保护隐私政策指引</h3>
                  <h3>十、通知和修订</h3>
                  <h3>十一、如何联系域起公司</h3>
                  <p>一、收集和使用个人信息的范围、方式和目的</p>
                  <p>
                    （一）为了满足相关法律法规及相关主管部门的要求，域起公司游戏用户需进行实名认证以继续使用游戏服务。域起公司会在获得您同意或你主动提供的情况下收集您的实名身份信息，包括身份证信息、军官证信息、护照信息、手机号，拒绝提供实名身份信息可能会导致您无法登录游戏或在使用域起游戏服务过程中收到相应限制。
                  </p>
                  <p>
                    （二）为了将您标注为域起公司的用户以及处理账号归属纠纷，如果您使用微信、QQ或发行渠道账号关联登录游戏，域起公司会收集您微信、QQ或发行渠道账号对应的唯一标记（包括账号昵称、头像、地区、性别）和您主动填写的其他信息（如邮箱、生日、籍贯、性别、兴趣爱好），用于保存您的登录信息，以便您在不同设备登录以及方便您分享游戏内容。
                  </p>
                  <p>
                    （三）为保障您正常使用域起网络的游戏服务，确保游戏服务功能的正常运行，优化游戏产品体验，保障您的账号安全，在您注册或使用域起公司游戏服务时，域起公司会使用MSA移动安全联盟软件开发工具包等工具收集您的个人常用设备信息、网络身份识别信息、后台进程、网络环境信息、包括设备ID(包括ios广告标识符（IDFA）、国际移动设备识别码（IMEI）、匿名设备标识符（OAID）、国际移动用户识别码（IMSI）、Android设备唯一标识（android_id）、全局唯一标识符（GUID）、设备类型和版本、系统版本、IP地址、MAC地址、应用ID、网络类型等信息。
                  </p>
                  <p>
                    （四）在您使用域起公司游戏服务的过程中，域起公司会收集您的系统日志信息、游戏日志信息、包括上网记录、登录日志、商店购买日志、活动日志、公会日志、升级日志、聊天日志，以便您能够在客户端查到您的游戏历史记录。上述信息同时会用于游戏运营统计分析、客服投诉处理、游戏反作弊分析，以及游戏体验优化提升。
                  </p>
                  <p>
                    （五）当您在域起公司游戏中进行充值、支付时，域起公司会收集您的充值记录、消费记录、余额、优惠券、游戏兑换码信息，以便您查询交易记录，以及在交易出现争议时，为客服人员提供信息以支持争议诉求的处理。同时，为了尽最大程度保护您的财产安全，域起公司会收集支付时的设备信息、网络身份识别信息和网络环境信息，以对高风险行为进行识别、二次确认或阻断。
                  </p>
                  <p>
                    （六）为了保障您的游戏账号安全，营造公平、健康及安全的游戏环境，域起公司会收集您的用户名、密码、短信、通讯录、设备信息、网络身份识别信息、游戏应用信息、操作系统、网络环境信息、游戏崩溃记录，以用于打击破坏游戏公平环境或干扰、破坏游戏服务正常进行的行为（如外挂作弊等）。
                  </p>
                  <p>
                    （七）域起公司会获取您设备的外部储存和读取权限，以用于登录信息缓存、自动登录。域起公司会获取您的位置信息，以用于异地登录判断等反盗号规则。
                  </p>
                  <p>
                    （八）当您在域起公司游戏或网站中咨询域起公司客服时，域起公司会收集您的充值记录、身份证信息、军官证信息、护照信息、手机号码、邮箱、游戏区服、游戏昵称等，获取您设备的拍照、相册权限，以用于上传账号所属证明、判断您的账号归属、确保您的账号安全。
                  </p>
                  <p>
                    （九）请您理解，域起公司向您提供的功能和服务是不断更新和发展的，如某一功能或服务未在前述说明中且收集了您的信息，域起公司会通过推送通知、交互流程、网站公告、更新隐私政策等方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。
                  </p>
                  <p>二、如何收集和使用信息</p>
                  <p>（一）域起公司将通过以下途径收集和获得您的信息：</p>
                  <p>1、您提供的信息。</p>
                  <p>
                    （1）您在注册域起公司服务的帐号或使用域起公司服务时主动提供的信息；
                  </p>
                  <p>
                    （2）您通过域起公司服务向其他方提供的共享信息，以及您使用域起公司服务时所储存的信息。
                    2、其他方分享的您的信息。以及其他方使用域起公司服务时所提供有关您的共享信息。
                    3、域起公司获取的您的信息。您在使用域起公司服务时，域起公司收集、汇总、记录的信息。
                  </p>
                  <p>（二）域起公司会出于以下目的，收集和使用您的信息：</p>
                  <p>1、帮助您完成注册</p>
                  <p>
                    为便于域起公司为您提供服务，您需要提供基本注册信息，例如手机号码、电子邮箱地址等，并创建您的用户名和密码。在部分单项服务中，如果您仅需使用浏览、搜索等基本服务，您不需要注册成为域起公司用户及提供上述信息。
                  </p>
                  <p>2、向您提供商品或服务</p>
                  <p>
                    域起公司所收集和使用的信息是为您提供域起公司服务的必要条件，如缺少相关信息，域起公司将无法为您提供域起公司服务的核心内容，例如：
                  </p>
                  <p>
                    （1）在部分服务项目中，为便于向您交付商品或服务，您需提供收货人个人身份信息、姓名、收货地址、邮政编码、收货人、联系电话、支付状态等信息。如果您拒绝提供此类信息，域起公司将无法完成相关交付服务。如您通过域起公司服务为其他人订购商品或服务，您需要提供该实际订购人的前述信息。向域起公司提供该实际订购人的前述信息之前，您需确保您已经取得其授权同意。
                  </p>
                  <p>
                    （2）为展示您账户的订单信息，域起公司会收集您在使用域起公司服务过程中产生的订单信息用于向您展示及便于您对订单进行管理；
                  </p>
                  <p>
                    （3）当您与域起公司联系时，域起公司可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
                  </p>
                  <p>
                    （4）为确认交易状态及为您提供售后与争议解决服务，域起公司会通过您基于交易所选择的交易对象、支付机构、物流公司等收集与交易进度相关的您的交易、支付、物流信息，或将您的交易信息共享给上述服务提供者。
                  </p>
                  <p>3、向您推送消息</p>
                  <p>
                    （1）为您展示和推送商品或服务。域起公司可能使用您的信息，您的浏览及搜索记录、设备信息、位置信息、订单信息，提取您的浏览、搜索偏好、行为习惯、位置信息等特征，并基于特征标签通过电子邮件、短信或其他方式向您发送营销信息，提供或推广域起公司或第三方的如下商品和服务：
                    域起公司的商品和服务，包括但不限于：即时通讯服务、网上媒体服务、互动娱乐服务、社交网络服务、支付服务、应用软件和服务、娱乐、网络游戏、电子商务、资讯及通讯软件和服务（“互联网服务”）；
                    及第三方商品和服务，包括但不限于：互联网服务、书册、杂志和其他刊物、服装和配饰、珠宝、化妆品、个人健康和卫生、电子、收藏品、家用器皿、电器、家居装饰和摆设、积分和奖励计划，以及域起公司认为可能与您相关的其他商品和服务。
                  </p>
                  <p>
                    （2）向您发出通知。域起公司可能在必需时（例如当域起公司由于系统维护而暂停某一单项服务、变更、终止提供某一单项服务时）向您发出与服务有关的通知。
                    如您不希望继续接收域起公司推送的消息，您可致电400-903-1158联系域起公司要求域起公司停止推送，但域起公司依法律规定或单项服务的服务协议约定发送消息的情形除外。
                  </p>
                  <p>4、为您提供安全保障</p>
                  <p>
                    为确保您身份真实性、向您提供更好的安全保障，您可以向域起公司提供身份证明、面部特征等生物识别信息等个人敏感信息以完成实名认证。
                    除身份验证外，域起公司可能将您的信息用于客户服务、安全防范、诈骗监测、存档和备份等用途，确保域起公司向您提供的服务的安全性；域起公司可能使用或整合域起公司所收集的您的信息，以及域起公司的合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
                  </p>
                  <p>5、改进域起公司的服务</p>
                  <p>
                    域起公司可能将通过某一项域起公司服务所收集的信息，用于域起公司的其他服务。例如，在您使用某一项域起公司服务时所收集的您的信息，可能在另一项域起公司服务中用于向您提供特定内容或向您展示与您相关的、而非普遍推送的信息；域起公司可能让您参与有关域起公司服务的调查，帮助域起公司改善现有服务或设计新服务；同时，域起公司可能将您的信息用于软件更新。
                    您了解并同意，在收集您的信息后，域起公司将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别您的身份，在此情况下域起公司有权使用已经去标识化的信息，对用户数据库进行分析并予以商业化的利用。
                  </p>
                  <p>
                    如果域起公司将您的信息用于本《隐私政策》中未载明的其他用途，会事先征求您同意。
                  </p>
                  <p>6、征得授权同意的例外</p>
                  <p>
                    根据相关法律法规规定，以下情形中收集您的信息无需征得您的授权同意：
                  </p>
                  <p>（1）与国家安全、国防安全有关的；</p>
                  <p>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
                  <p>（3）与犯罪侦查、起诉、审判和判决执行等有关的；</p>
                  <p>
                    （4）出于维护信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
                  </p>
                  <p>（5）所收集的信息是您自行向社会公众公开的；</p>
                  <p>
                    （6）从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；
                  </p>
                  <p>（7）根据您的要求签订合同所必需的；</p>
                  <p>
                    （8）用于维护域起公司服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
                  </p>
                  <p>（9）为合法的新闻报道所必需的；</p>
                  <p>
                    （10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；
                  </p>
                  <p>（11）法律法规规定的其他情形。</p>
                  <p>
                    三、如何使用COOKIES技术
                    域起公司或域起公司的第三方合作伙伴可能通过COOKIES及相关技术收集和使用您的信息，并将该等信息储存。
                  </p>
                  <p>（一）COOKIES</p>
                  <p>
                    COOKIES
                    是由用户访问的网站向浏览器发送的一小段文本，它通常包含标识符、站点名称以及一些号码和字符。它帮助浏览器记录有关访问活动的信息，例如会话信息、首选语言和其他一些设置，它是一种让网站服务器将数据存储于客户端或从客户端中读取数据的中立技术。
                  </p>
                  <p>COOKIES的使用</p>
                  <p>
                    COOKIES内容通常均经过加密，安全的COOKIES技术会为用户在上网时提供更为便捷的服务。
                  </p>
                  <p>
                    COOKIES能帮助域起公司确定您连接的页面和内容，您在域起公司特定服务上花费的时间和您所选择的域起公司服务，您可查阅您浏览器附带的文件以获得更多这方面的信息。
                  </p>
                  <p>
                    域起公司使用自己的COOKIES及相关技术，用于以下用途：
                  </p>
                  <p>（1）记住您的身份。</p>
                  COOKIES及相关技术有助于域起公司辨认您作为域起公司的注册用户的身份，或保存您向域起公司提供有关您的喜好或其他信息；

                  <p>  （2）分析您使用域起公司服务的情况。</p>
                  域起公司可利用COOKIES及相关技术来了解您使用域起公司服务进行什么活动或哪些服务最受欢迎；

                  <p />
                  <p>四、可能分享、转让和披露的信息</p>
                  <p>（一）分享</p>
                  除以下情形外，未经您同意，域起公司不会与域起公司之外的任何第三方分享您的信息：
                  <p>  1、向您提供域起公司的服务。域起公司可能向合作伙伴及其他第三方分享您的信息，以实现您需要的核心功能或提供您需要的服务，例如：向物流服务商提供对应的订单信息；</p>
                  <p> 2、维护和改善域起公司的服务。域起公司可能向合作伙伴及其他第三方分享您的信息，以帮助域起公司为您提供更有针对性、更完善的服务，例如：代表域起公司发出电子邮件或推送通知的通讯服务提供商等；</p>
                  <p>  3、实现本《隐私政策》第二条“域起公司如何收集和使用信息”部分所述目的；</p>
                  <p>   4、履行域起公司在本《隐私政策》或域起公司与您达成的其他协议中的义务和行使域起公司的权利；</p>
                  <p>  5、向委托域起公司进行推广的合作伙伴等第三方共享，但域起公司仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供可以识别您身份的信息，例如姓名电话号码或电子邮箱；或者域起公司将这些信息进行汇总，以便它不会识别您个人。比如域起公司可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。</p>
                  <p>  6、在法律法规允许的范围内，为了遵守法律、维护域起公司及域起公司的关联方或合作伙伴、您或其他域起公司用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，域起公司可能与其他公司和组织交换信息。不过,这并不包括违反本《隐私政策》中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的信息。
                  </p>
                  <p> 7、应您合法需求，协助处理您与他人的纠纷或争议；</p>
                  <p> 8、应您的监护人合法要求而提供您的信息；</p>
                  <p>9、根据与您签署的单项服务协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</p>
                  <p> 10、基于学术研究而提供；</p>
                  <p> 11、基于符合法律法规的社会公共利益而提供。</p>
                  域起公司仅会出于合法、正当、必要、特定、明确的目的共享您的信息。对域起公司与之共享信息的公司、组织和个人，域起公司会与其签署严格的保密协定，要求他们按照域起公司的说明、本《隐私政策》以及其他任何相关的保密和安全措施来处理信息。
                  <p> （二）转让</p>
                  <p>   1、随着域起公司业务的持续发展，域起公司有可能进行合并、收购、资产转让或类似的交易，而您的信息有可能作为此类交易的一部分而被转移。域起公司会要求新的持有您信息的公司、组织继续受本《隐私政策》的约束，否则,域起公司将要求该公司、组织重新向您征求授权同意。
                  </p>

                  <p> 2、在获得您的明确同意后，域起公司会向其他方转让您的信息。</p>

                  <p>（三）披露</p>
                  域起公司仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的信息：
                  <p> 1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息；</p>
                  <p>   2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，域起公司可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当域起公司收到上述披露信息的请求时，域起公司会要求接收方必须出具与之相应的法律文件，如传票或调查函。域起公司坚信，对于要求域起公司提供的信息，应该在法律允许的范围内尽可能保持透明。域起公司对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。
                  </p>
                  <p>五、保留、储存和保护信息</p>
                  <p>
                    域起公司仅在本《隐私政策》所述目的所必需期间和法律法规要求的时限内保留您的信息。</p>
                  <p> 域起公司在中华人民共和国境内运营中收集和产生的信息，存储在中国境内。</p>
                  <p>  域起公司将采取以下手段保护您的信息： </p>
                  <p>（一）数据安全技术措施</p>
                  域起公司会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。网络服务采取了多种加密技术，例如在某些服务中，域起公司将利用加密技术（例如SSL）来保护您的信息，采取加密技术对您的信息进行加密保存，并通过隔离技术进行隔离。
                  在信息使用时，例如信息展示、信息关联计算，域起公司会采用多种数据脱敏技术增强信息在使用中安全性。采用严格的数据访问权限控制和多重身份认证技术保护信息，避免数据被违规使用。
                  <p>（二）域起公司为保护信息采取的其他安全措施</p>

                  域起公司通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范信息的存储和使用。
                  域起公司通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。
                  加强安全意识。域起公司还会举办安全和隐私保护培训课程，加强员工对于保护信息重要性的认识。
                  <p>（三）域起公司仅允许有必要知晓这些信息的域起公司员工、合作伙伴访问您的信息，并为此设置了严格的访问权限控制和监控机制。域起公司同时要求可能接触到您的信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与域起公司的合作关系。</p>
                  <p>（四）域起公司会采取一切合理可行的措施，确保未收集无关的信息。</p>
                  <p>（五）互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，域起公司建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。</p>
                  <p>
                    （六）互联网环境并非百分之百安全，域起公司将尽力确保或担保您发送给域起公司的任何信息的安全性。如果域起公司的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，域起公司将承担相应的法律责任。</p>
                  <p>（七）安全事件处置</p>
                  <p>在通过域起公司服务与第三方进行沟通或购买商品及服务时，您不可避免的要向交易对方或潜在的交易对方披露自己的信息，如联络方式或者邮政地址等。请您妥善保护自己的信息，仅在必要的情形下向他人提供。
                    为应对信息泄露、损毁和丢失等可能出现的风险，域起公司制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。域起公司也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
                    在不幸发生信息安全事件后，域起公司将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、域起公司已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。域起公司同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，域起公司会采取合理、有效的方式发布公告。同时，域起公司还将按照监管部门要求，主动上报信息安全事件的处置情况。
                    请您理解，由于技术的限制以及风险防范的局限，即便域起公司已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入域起公司服务所用的系统和通讯网络，有可能因域起公司可控范围外的情况而发生问题。
                  </p>
                  <p>如何管理您的信息</p>
                  <p>（一）访问、更新和删除</p>
                  <p>
                    域起公司鼓励您更新和修改您的信息以使其更准确有效。您能通过域起公司服务访问您的信息，并根据对应信息的管理方式自行完成或要求域起公司进行修改、补充和删除。域起公司将采取适当的技术手段，尽可能保证您可以访问、更新和更正自己的信息或使用域起公司服务时提供的其他信息。
                    在访问、更新、更正和删除前述信息时，域起公司可能会要求您进行身份验证，以保障信息安全。对于通过COOKIES和WEB
                    BEACON收集的您的信息，域起公司还在本《隐私政策》第二条“COOKIES、日志档案和WEB
                    BEACON”部分说明了向您提供的选择机制。如您想查询、修改或删除您的部分信息，请按照单项服务的具体指引进行操作。
                  </p>
                  <p>（二）公开与分享</p>
                  域起公司的多项服务可让您不仅与您的社交网络、也与使用该服务的所有用户公开分享您的相关信息，例如，您在域起公司服务中所上传或发布的信息、您对其他人上传或发布的信息作出的回应，通过电子邮件或在域起公司服务中不特定用户可见的公开区域内上传或公布您的个人信息，以及包括与这些信息有关的位置数据和日志信息。只要您不删除您所公开或共享的信息，有关信息可能一直留存在公众领域；即使您删除共享信息，有关信息仍可能由其他用户或不受域起公司控制的第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公众领域保存。如您将信息通过上述渠道公开或共享，由此造成您的信息泄露，域起公司不承担责任。因此，域起公司提醒并请您慎重考虑是否通过上述渠道公开或共享您的信息。

                  <p>（三）注销</p>
                  在符合域起公司单项服务的服务协议约定条件及国家相关法律法规规定的情况下，您的该项域起公司服务帐号可能被注销或删除。当帐号注销或被删除后，与该帐号相关的、该单项服务项下的全部服务资料和数据将依照单项服务的服务协议约定删除或处理。

                  <p>（四）改变您授权同意的范围</p>
                  您总是可以选择是否披露信息。有些信息是使用域起公司服务所必需的，但大多数其他信息的提供是由您决定的。您可以通过删除信息、关闭设备功能等方式改变您授权域起公司继续收集信息的范围或撤回您的授权。
                  当您撤回授权后，域起公司无法继续为您提供撤回授权所对应的服务，也不再处理您相应的信息。但您撤回授权的决定，不会影响此前基于您的授权而开展的信息处理。
                  <p> （五）有关敏感信息的提示</p>
                  某些信息因其特殊性可能被认为是敏感信息，例如您的种族、宗教、个人健康和医疗信息等，以及身份证明文件、个人生物识别信息、财产信息、行踪轨迹、未成年人的信息等。
                  请注意，您在域起公司服务中所提供、上传或发布的内容和信息（例如有关您社交活动的照片或信息），可能会泄露您的敏感信息。您需要谨慎地考虑，是否使用域起公司服务披露您的敏感信息。
                  您同意您的敏感信息按本《隐私政策》所述的目的和方式来处理。
                  <p>七、第三方服务</p>
                  <p>
                    域起公司服务可能链接至第三方提供的社交媒体或其他服务（包括网站或其他服务形式）。包括：
                  </p>
                  <p>
                    （一）您可利用“分享”键将某些内容分享到域起公司服务，或您可利用第三方服务登录域起公司服务。这些功能可能会收集您的信息（包括您的日志信息），并可能在您的电脑装置COOKIES，从而正常运行上述功能；
                  </p>
                  <p>
                    （二）我们通过广告或我们服务的其他方式向您提供链接，使您可以接入第三方的服务或网站；
                  </p>
                  <p>
                    （三）其他接入第三方服务的情形。您使用第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本《隐私政策》）约束，您需要仔细阅读其条款。本《隐私政策》仅适用于我们所收集的任何信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，而我们对任何第三方使用由您提供的信息不承担任何责任。
                  </p>
                  <p>八、年龄限制</p>
                  <p>
                    （一）域起公司高度重视未成年人个人信息的保护问题。建议任何未成年人参加网上活动应事先取得家长或其法定监护人（以下简称"监护人"）的同意。域起公司也将根据国家相关法律法规的规定保护未成年人个人信息的保密性及安全性。
                  </p>
                  <p>
                    （二）域起公司将按照国家防沉迷政策要求，通过实名身份等信息校验判断相关账号的实名信息是否为未成年人，进而决定是否将此账号纳入到防沉迷体系中，通过启用防沉迷系统保护未成年人（包括儿童）的合法权益。
                  </p>
                  <p>
                    （三）域起公司鼓励父母或监护人指导未成年人使用域起公司服务。域起公司建议未成年人鼓励他们的父母或监护人阅读本政策，并建议未成年人在提交信息之前寻求父母或监护人的同意和指导。
                  </p>
                  <p>
                    （四）若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过第十部分中的联系方式与域起公司联系。
                  </p>
                  <p>九、域起儿童保护隐私政策指引</p>
                  <p>
                    详见《域起儿童保护隐私政策指引》https://www.gg.com/child.html。
                  </p>
                  <p>十、通知和修订</p>
                  <p>
                    域起公司可能适时修改本《隐私政策》的条款，该等修改构成本《隐私政策》的一部分。对于重大变更，域起公司会提供更显著的通知，您可以选择停止使用域起公司服务；在该种情况下，如您仍然继续使用域起公司服务的，即表示同意受经修订的本《隐私政策》的约束。
                    任何修改都会将您的满意度置于首位。域起公司鼓励您在每次使用域起公司服务时都查阅域起公司的隐私政策。
                    域起公司可能在必需时（例如当域起公司由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。
                    最后，您必须对您的账号和密码信息负有保密义务。任何情况下，请小心妥善保管。
                  </p>
                  <p>十一、如何联系域起公司</p>
                  <p>
                    如您有关于网络信息安全的投诉和举报，或您对本《隐私政策》、您的信息的相关事宜有任何问题、意见或建议，以及有关本声明或域起公司的隐私措施的问题请致电400-903-1158与域起公司联系。
                  </p>
                  <p>
                    附件：《网络游戏用户隐私权保护和个人信息利用政策》 【注意】
                    根据文化部制定的《网络游戏管理暂行办法》及《网络游戏服务格式化协议必备条款》（下称“必备条款”）的相关规定，为保障网络游戏用户（下称“用户”）的隐私权、规范对网络游戏用户个人信息的利用，特制定本政策。请用户仔细阅读以下全部内容。如用户不同意本政策的任意内容，请不要注册或使用域起公司服务。如用户进入注册程序，即表示用户与域起公司已达成协议，自愿接受本政策的所有内容。此后，用户不得以未阅读本政策的内容作任何形式的抗辩。
                    尊重用户个人隐私是域起公司的一项基本政策。“隐私”是指用户在注册域起公司帐号时提供给域起公司的个人身份信息，包括但不限于用户注册资料中的姓名、个人有效身份证件号码、联系方式、家庭住址等。域起公司一贯积极地采取技术与管理等合理措施保障用户帐号的安全、有效；域起公司将善意使用收集的信息，采取各项有效且必要的措施以保护您的隐私安全，并使用商业上合理的安全技术措施来保护您的隐私不被未经授权的访问、使用或泄漏。
                    因业务所需，域起公司需与域起公司的关联公司及第三方共享必要的用户注册信息，域起公司及关联公司承诺将善意使用用户信息，域起公司将不会向除关联公司外的任何其他方公开或共享用户注册资料的中的姓名、个人有效身份证件号码、联系方式、家庭住址等个人身份信息，但下列情况除外：
                  </p><p>（一）用户或用户监护人授权域起公司披露的；</p>
                  <p>（二）有关法律要求域起公司披露的；</p>
                  <p>（三）司法机关或行政机关基于法定程序要求域起公司提供的；</p>
                  <p>（四）域起公司为了维护自己合法权益而向用户提起诉讼或者仲裁时；</p>
                  <p>（五）应用户监护人的合法要求而提供用户个人身份信息时；</p>
                  <p>（六）因向用户提供服务的需要，向第三方提供用户本人及指定代理人的必要信息时。</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
  }
}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

body {
  background-color: #fff;
  /* font-size: 1rem; */
}

.help {
  color: #e62631 !important;
}

.big_logo .bgi {
  width: 100%;
}

.doing-what {
  border-left: 0.25rem solid #3a79d4;
  font-size: 1.375rem;
  padding-left: 1.25rem;
  line-height: 1.75rem;
  margin: 1.25rem 20.625rem;
}

.help-content {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

.help-content .help-box {
  margin: 2.5rem 0;
  overflow: hidden;
  width: 71.25rem;
  background-color: #fff;
  padding: 1.625rem;
  box-sizing: border-box;
  border-radius: 0.375rem;
  border: 0.125rem solid #eee;
}

.help-content .help-box li {
  background-color: #f5f5f5;
  border-radius: 0.375rem;
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
}

.help-content .help-box li .top {
  width: 67.375rem;
  height: 4.125rem;
  padding: 0 2.5rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.help-content .help-box li:last-of-type {
  margin-bottom: 0;
}

.help-content .help-box li .jiantou {
  width: 0.625rem;
  height: 0.625rem;
  border-left: 0.125rem solid #ccc;
  border-bottom: 0.125rem solid #ccc;
  transform: rotate(-45deg);
}

.help-content .help-box li:last-of-type .jiantou {
  transform: rotate(-45deg);
}

.help-content .help-box li .problem {
  padding: 0 2.5rem 1.25rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.help-content .help-box li .problem .topic {
  color: red;
  margin-top: 1.25rem;
}

.help-content .help-box li .problem .content {
  padding: 1.25rem;
  margin-top: 1.25rem;
  background-color: #f5f5f5;
  border-radius: 0.375rem;
  border: 0.065rem solid #e7e7e7;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  vertical-align: top;
}

.help-content .help-box li .problem .content span:not(:last-of-type) {
  height: 2.5rem;
}

.help-content .help-box li .bottom {
  border-top: 0.065rem solid #eee;
  display: block;
}

.problem p {
  cursor: pointer;
}

.problem .bottomic {
  display: none;
}
.p_con,
.p_join {
  line-height: 2.5rem;
  font-size: 1rem;
  text-align: center;
}

.p_content .p_title {
  font-size: 1.1174rem;
  font-weight: 700;
  line-height: 3rem;
}
.title {
  text-align: center;
}
.p_content .p_line {
  font-size: 2rem;
  width: 1.25rem;
  color: #c4222e;
}
.p_content .p_icon {
  text-indent: 2em;
  line-height: 2.4rem;
  font-size: 0.875rem;
}
.p_content .min_title {
  font-size: 0.9376rem;
  font-weight: 700;
}
.left {
  float: left;
}
.media {
  margin-left: 6.25rem;
  margin-top: 2.5rem;
}
.p_content img {
  width: 100%;
}
.black-ground {
  position: fixed;
  align-items: center;
  width: 100%;
  height: 4rem;
  top: 0;
}

.dh-ground {
  position: fixed;
  z-index: 2;
  width: 15rem;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}
h3,p,h1,h2,h4,h5,li {
  font-size: 1rem !important;
}
p {
}
a {
  color: blue;
  line-height: 10px;
}
</style>
